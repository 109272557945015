import styled from 'styled-components';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { interfaces } from './';
import { theme as friendzoneTheme } from './styles/theme';

// Normal Input
export const Input = styled.input`
  padding: 12px;
  border-radius: 6px;
  font-size: ${({ theme }: interfaces.IStyledComponent) => theme.defaultFontSize};
  color: ${({ theme }: interfaces.IStyledComponent) => theme.colors.defaultFontColor};
  border: ${({ theme }: interfaces.IStyledComponent) => `1px solid ${theme.colors.inputBorder}`};

  margin-bottom: 6px;

  ::placeholder {
    color: ${({ theme }: interfaces.IStyledComponent) => theme.colors.placeholder};
  }
`;

// Password Input
const PasswordInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }: interfaces.IStyledComponent) => theme.colors.black};
  background-color: ${({ theme }: interfaces.IStyledComponent) => theme.colors.white};
  border: ${({ theme }: interfaces.IStyledComponent) => `1px solid ${theme.colors.inputBorder}`};

  padding: 12px;
  border-radius: 6px;
  margin-bottom: 6px;
`;

const PasswordEyeButton = styled.button`
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

const PasswordInputNoBorder = styled.input`
  flex: 1;
  border: none;
  font-size: 1rem;
  color: ${({ theme }: interfaces.IStyledComponent) => theme.colors.defaultFontColor};
`;

export const PasswordInput = ({ value, onChange }: any) => {
  const [isPasswordMasked, setIsPasswordMasked] = useState<boolean>(true);
  return (
    <PasswordInputWrapper>
      <PasswordInputNoBorder
        value={value}
        onChange={onChange}
        type={isPasswordMasked ? 'password' : 'text'}
      />
      <PasswordEyeButton onClick={() => setIsPasswordMasked(!isPasswordMasked)}>
        <FontAwesomeIcon
          icon={isPasswordMasked ? faEye : faEyeSlash}
          color={friendzoneTheme.colors.black}
        />
      </PasswordEyeButton>
    </PasswordInputWrapper>
  );
};
