import React, { ReactNode, ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { interfaces } from './';

interface IStyledButton extends interfaces.IStyledComponent, ButtonHTMLAttributes<HTMLElement> {
  onClick: any;
  children: ReactNode;
  isLoading?: boolean;
}

const StyledButton = ({ isLoading, children, onClick, ...rest }: IStyledButton) => (
  <button onClick={onClick} {...rest}>
    {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : children}
  </button>
);

export const Button = styled(StyledButton)`
  cursor: pointer;
  font-size: ${({ theme }: interfaces.IStyledComponent) => theme.defaultFontSize};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.offWhite};
  border: ${({ theme }) => `1px solid ${theme.colors.white}`};
  min-width: 48px;

  padding: 12px;
  border-radius: 6px;
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.white}`};

  :disabled {
    background-color: ${({ theme }) => theme.colors.disabled};
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.disabled}`};
  }

  :focus {
    background-color: ${({ theme }) => theme.colors.primarySelected};
    border-bottom: ${({ theme }) => `2px solid ${theme.colors.white}`};
  }

  :active {
    background-color: ${({ theme }) => theme.colors.primaryActivated};
    transform: translateY(2px);
  }

  ${props =>
    props.isLoading &&
    css`
      background-color: ${({ theme }) => theme.colors.disabled};
    `}
`;
