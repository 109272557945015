import React, { useState, useEffect } from 'react';
import { Layout } from '../Layout';

export const Router = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(false);
  }, [isLoading]);

  return isLoading ? <div>Loading...</div> : <Layout />;
};
