import React from 'react';
import styled from 'styled-components';

const StyledContent = styled.div`
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.black};
  padding: 12px;
`;

export const Content: React.FC = ({ children }) => {
  return <StyledContent>{children}</StyledContent>;
};
