// Palette 3
export const palette = {
  // Primary
  purple050: '#E6E6FF',
  purple100: '#C4C6FF',
  purple200: '#A2A5FC',
  purple300: '#8888FC',
  purple400: '#7069FA',
  purple500: '#5D55FA',
  purple600: '#4D3DF7',
  purple700: '#3525E6',
  purple800: '#1D0EBE',
  purple900: '#0C008C',

  teal050: '#EFFCF6',
  teal100: '#C6F7E2',
  teal200: '#8EEDC7',
  teal300: '#65D6AD',
  teal400: '#3EBD93',
  teal500: '#27AB83',
  teal600: '#199473',
  teal700: '#147D64',
  teal800: '#0C6B58',
  teal900: '#014D40',

  // Neutrals
  blueGrey050: '#F0F4F8',
  blueGrey100: '#D9E2EC',
  blueGrey200: '#BCCCDC',
  blueGrey300: '#9FB3C8',
  blueGrey400: '#829AB1',
  blueGrey500: '#627D98',
  blueGrey600: '#486581',
  blueGrey700: '#334E68',
  blueGrey800: '#243B53',
  blueGrey900: '#102A43',

  // Supporting
  lightBlueVivid050: '#E3F8FF',
  lightBlueVivid100: '#B3ECFF',
  lightBlueVivid200: '#81DEFD',
  lightBlueVivid300: '#5ED0FA',
  lightBlueVivid400: '#40C3F7',
  lightBlueVivid500: '#2BB0ED',
  lightBlueVivid600: '#1992D4',
  lightBlueVivid700: '#127FBF',
  lightBlueVivid800: '#0B69A3',
  lightBlueVivid900: '#035388',

  redVivid050: '#FFE3E3',
  redVivid100: '#FFBDBD',
  redVivid200: '#FF9B9B',
  redVivid300: '#F86A6A',
  redVivid400: '#EF4E4E',
  redVivid500: '#E12D39',
  redVivid600: '#CF1124',
  redVivid700: '#AB091E',
  redVivid800: '#8A041A',
  redVivid900: '#610316',

  yellowVivid050: '#FFFBEA',
  yellowVivid100: '#FFF3C4',
  yellowVivid200: '#FCE588',
  yellowVivid300: '#FADB5F',
  yellowVivid400: '#F7C948',
  yellowVivid500: '#F0B429',
  yellowVivid600: '#DE911D',
  yellowVivid700: '#CB6E17',
  yellowVivid800: '#B44D12',
  yellowVivid900: '#8D2B0B',
};
