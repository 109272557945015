import React from 'react';

import { SequencerEditBay } from './SequencerEditBay';
import { SequencerController } from './SequencerController';

export const Sequencer = () => {
  return (
    <section>
      <SequencerController />
      <SequencerEditBay />
    </section>
  );
};
