import React, { Fragment, useRef, useEffect } from 'react';
import styled from 'styled-components';

import { ISequencerTrack } from '../interfaces';
import { SequencerPad } from './SequencerPad';
import { PlaybackState } from './SequencerStore';

const TrackContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(4, 0.25fr 1fr 1fr 1fr 1fr);
  grid-template-rows: 48px;
  grid-column-gap: 6px;
  margin: 12px 6px;
`;

const TrackTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: monospace;
  color: ${({ theme }) => theme.colors.white};
`;

interface IProps {
  track: ISequencerTrack;
  togglePlayPosition: (index: number) => void;
  currentStep: number;
  currentPlayState: PlaybackState;
}

export const SequencerTrack = ({
  track,
  togglePlayPosition,
  currentStep,
  currentPlayState,
}: IProps) => {
  const audioTrackPlayer = useRef(track.audioTrack ? new Audio(track.audioTrack) : null);

  const playAudio = () => {
    if (audioTrackPlayer.current) {
      audioTrackPlayer.current.currentTime = 0;
      audioTrackPlayer.current.play();
    }
  };

  useEffect(() => {
    if (currentPlayState === PlaybackState.Playing && track.playPositions[currentStep] === true) {
      playAudio();
    }
  }, [currentStep, currentPlayState, track.playPositions]);

  const tempTitle = track.icon ? (
    <img src={track.icon} height={48} alt={track.title} />
  ) : (
    <TrackTitle>{track.title}</TrackTitle>
  );

  return (
    <TrackContainer>
      {tempTitle}
      {track.playPositions.map((isActive: boolean, index: number) => {
        // Generate a gap for every measure
        let tempGapDiv = index % 4 === 0 ? <div style={{ flex: 1 }}></div> : null;
        return (
          <Fragment key={`gap-${index}`}>
            {tempGapDiv}
            <SequencerPad
              key={`${track.title}-${index}`}
              activeColor={track.color}
              isActive={isActive}
              isSelected={currentStep !== null && currentStep === index ? true : false}
              onClick={() => togglePlayPosition(index)}
            />
          </Fragment>
        );
      })}
    </TrackContainer>
  );
};
