import React from 'react';
import styled from 'styled-components';

export const Header: React.FC = () => {
  return <StyledHeader>DOINKIN-O-TRON 5000</StyledHeader>;
};

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  color: ${({ theme }) => theme.colors.white};

  font-family: monospace;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.white}`};
`;
