import { createGlobalStyle } from 'styled-components';
import { interfaces } from '../';

export const GlobalStyles = createGlobalStyle<interfaces.IStyledComponent>`
  /* RESET */
  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed,  figure, figcaption, footer, header, hgroup,  menu, nav, output, ruby, section, summary, time, mark, audio, video {  
   margin: 0;  
   padding: 0;  
   border: 0;  
   font-size: 100%;  
   font: inherit;  
   vertical-align: baseline;  
  }

  html {
    height: 100vh;
    width: 100vw;
  }

  body {
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.textWhite};
    height: 100vh;
    width: 100vw;
    font-family: monospace;
    font-size: 1rem;
  }

  #root {
    height: 100vh;
    width: 100vw;
  }

  a { 
    color: ${({ theme }) => theme.colors.textWhite};
  }
`;
