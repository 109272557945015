import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding-bottom: 6px;

  font-family: monospace;
  font-size: 12px;
`;

const StyledFooterText = styled.div`
  align-self: end;
  padding-right: 6px;
`;

const AttributionText = styled.div`
  align-self: end;
  padding-left: 6px;
`;

export const Footer: React.FC = () => {
  return (
    <StyledFooter>
      <AttributionText>
        Icons made by{' '}
        <a href="https://www.flaticon.com/authors/eucalyp" title="Eucalyp">
          Eucalyp
        </a>{' '}
        and{' '}
        <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
          Freepik
        </a>{' '}
        from{' '}
        <a href="https://www.flaticon.com/" title="Flaticon">
          www.flaticon.com
        </a>
      </AttributionText>
      <StyledFooterText>duncan iaria // 2019</StyledFooterText>
    </StyledFooter>
  );
};
