import { palette } from './colorPalette';

export type ThemeType = {
  headerHeight: string;
  footerHeight: string;
  defaultFontSize: string;
  colors: {
    primary: string;
    primarySelected: string;
    primaryActivated: string;
    inputBorder: string;
    defaultFontColor: string;
    highlight: string;
    secondary: string;
    tertiary: string;
    offWhite: string;
    white: string;
    textWhite: string;
    disabled: string;
    red: string;
    black: string;
    stroke: string;
    placeholder: string;
  };
};

export const theme = {
  headerHeight: `64px`,
  footerHeight: `24px`,
  defaultFontSize: `1rem`,
  colors: {
    primary: `#e82267`,
    primarySelected: `#e82267`,
    primaryActivated: `#e82267`,
    highlight: '#ff8906',
    secondary: '#f25f4c',
    tertiary: '#e53170',
    white: '#fffffe',
    textWhite: '#a7a9be',
    black: '#0f0e17',
    stroke: 'black',
    // inputs
    inputBorder: palette.blueGrey400,
    offWhite: palette.blueGrey050,
    defaultFontColor: palette.blueGrey700,
    disabled: palette.blueGrey400,
    placeholder: palette.blueGrey400,
  },
};
