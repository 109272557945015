import React, { createContext, useReducer, Dispatch, Reducer } from 'react';
import { sequencerConstants } from '../constants';
import { trackData as initialTrackData } from '../data';
import { ISequencerTrack } from '../interfaces';

interface ISequencerState {
  currentStep: number;
  maxSteps: number;
  playState: PlaybackState;
  trackData: Array<ISequencerTrack>;
  trackTickRate: number;
  BPM: number;
}

export interface ISequencerContext {
  state: ISequencerState;
  dispatch: Dispatch<ISequencerAction>;
}

enum SequencerAction {
  SetStep = 'SET_STEP',
  AdvanceStep = 'ADVANCE_STEP',
  SetPlaybackState = 'SET_PLAYBACK_STATE',
  StopPlayback = 'STOP_PLAYBACK',
  ToggleTrackAudioPosition = 'TOGGLE_TRACK_AUDIO_POSITION',
  ResetAudioPlayPositions = 'RESET_AUDIO_PLAY_POSITIONS',
  SetBPM = 'SET_BPM',
}

interface ISequencerAction {
  type: SequencerAction;
  payload: any;
}

enum PlaybackState {
  Paused = 'Paused',
  Playing = 'Playing',
}

const ONE_MINUTE = 60 * 1000;
const BPM = 120;
const TICK_TIME = ONE_MINUTE / BPM;

const initialState: ISequencerState = {
  currentStep: 0,
  trackTickRate: TICK_TIME,
  BPM,
  maxSteps: sequencerConstants.MAX_STEPS,
  playState: PlaybackState.Paused,
  trackData: [...initialTrackData],
};

const sequencerReducer: Reducer<ISequencerState, ISequencerAction> = (state, action) => {
  switch (action.type) {
    case SequencerAction.SetStep:
      return state;
    case SequencerAction.AdvanceStep:
      return {
        ...state,
        currentStep: state.currentStep >= sequencerConstants.MAX_STEPS ? 0 : state.currentStep + 1,
      };
    case SequencerAction.SetPlaybackState:
      return { ...state, playState: action.payload };
    case SequencerAction.StopPlayback:
      return { ...state, playState: PlaybackState.Paused, currentStep: 0 };
    case SequencerAction.ResetAudioPlayPositions:
      return { ...initialState };
    case SequencerAction.SetBPM:
      return {
        ...state,
        BPM: action.payload,
        trackTickRate: ONE_MINUTE / action.payload,
      };
    case SequencerAction.ToggleTrackAudioPosition: {
      return {
        ...state,
        trackData: state.trackData.map((tempTrackData: any, index: number) => {
          if (index === action.payload.trackIndex) {
            return {
              ...tempTrackData,
              playPositions: tempTrackData.playPositions.map(
                (tempTrackPlayPosition: boolean, index: number) => {
                  if (index === action.payload.positionIndex) {
                    return !tempTrackPlayPosition;
                  }
                  return tempTrackPlayPosition;
                }
              ),
            };
          }
          return tempTrackData;
        }),
      };
    }
    default:
      console.warn(`no action found for that action type: ${action.type}`);
      return state;
  }
};

const sequencerStore = createContext<ISequencerContext>({
  state: initialState,
  dispatch: () => {},
});

const { Provider } = sequencerStore;

const SequencerStateProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer<Reducer<ISequencerState, ISequencerAction>>(
    sequencerReducer,
    initialState
  );

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { sequencerStore, SequencerStateProvider, SequencerAction, PlaybackState };
