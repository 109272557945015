import React from 'react';
import styled from 'styled-components';

import { theme } from '../common';

const PadContainer = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;
`;

interface IProps {
  onClick: () => void;
  activeColor: string;
  isActive?: boolean;
  isSelected?: boolean;
}

export const SequencerPad = ({
  onClick,
  activeColor,
  isActive = false,
  isSelected = false,
}: IProps) => {
  let bgColor = isActive ? activeColor : `${theme.colors.black}`;
  let border = isSelected
    ? `2px solid ${theme.colors.white}`
    : `1px solid ${theme.colors.textWhite}`;

  return (
    <PadContainer onClick={onClick} style={{ border }}>
      <div style={{ backgroundColor: bgColor, flex: 1 }}></div>
    </PadContainer>
  );
};
