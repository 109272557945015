import React, { useContext } from 'react';
import styled from 'styled-components';

import { sequencerConstants } from '../constants';
import { sequencerStore, SequencerAction } from './SequencerStore';

const SliderContainer = styled.div`
  padding-right: 12px;
  display: flex;
  margin-left: auto;
  align-items: center;
`;

const SliderInput = styled.input`
  appearance: none;
  height: 4px;
  background-color: white;

  &::-webkit-slider-thumb {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    cursor: pointer;
    background: white;
    border: none;

    :hover {
      background-color: ${({ theme }) => theme.colors.secondary};
    }

    :active {
      background-color: ${({ theme }) => theme.colors.primaryActivated};
    }
  }

  &::-moz-range-thumb {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    cursor: pointer;
    background: white;
    border: none;

    :hover {
      background-color: ${({ theme }) => theme.colors.secondary};
    }

    :active {
      background-color: ${({ theme }) => theme.colors.primaryActivated};
    }
  }
`;

const BPMDisplay = styled.div`
  padding: 6px;
  margin-left: 12px;
`;

export const SequencerBPMController = () => {
  const sequencerState = useContext(sequencerStore);
  const { dispatch, state } = sequencerState;

  const onUpdateBPM = (sliderEvent: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: SequencerAction.SetBPM,
      payload: sliderEvent.target.value,
    });
  };

  return (
    <SliderContainer>
      <SliderInput
        type="range"
        min={sequencerConstants.MIN_BPM}
        max={sequencerConstants.MAX_BPM}
        onChange={onUpdateBPM}
        value={state.BPM}
        aria-label="BPM Control"
      />
      <BPMDisplay>{state.BPM}</BPMDisplay>
      <h1>BPM</h1>
    </SliderContainer>
  );
};
