import React from 'react';
import { ThemeProvider } from 'styled-components';

import { theme, GlobalStyles } from '../common';
import { Router } from './router/Router';
import { SequencerStateProvider } from '../sequencer';

// import { loop } from '../sequencer/SoundEngine';
// loop(Date.now());

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <SequencerStateProvider>
        <GlobalStyles />
        <Router />
      </SequencerStateProvider>
    </ThemeProvider>
  );
};

export default App;
