import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faStop, faHistory } from '@fortawesome/free-solid-svg-icons';

import { Button } from '../common';
import { SequencerBPMController } from './SequencerBPMController';
import { sequencerStore, SequencerAction, PlaybackState } from './SequencerStore';

const ControllerContainer = styled.section`
  display: grid;
  grid-template-columns: 1.25fr 1fr;
  margin-bottom: 12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0px 12px;
`;

export const SequencerController = () => {
  const sequencerState = useContext(sequencerStore);
  const { dispatch, state } = sequencerState;

  useEffect(() => {
    let stepInterval: any = null;
    if (state.playState === PlaybackState.Playing && !stepInterval) {
      stepInterval = setInterval(
        () => dispatch({ type: SequencerAction.AdvanceStep, payload: null }),
        state.trackTickRate
      );
    }

    // cleanup
    return () => stepInterval && clearInterval(stepInterval);
  }, [state.playState, state.trackTickRate, dispatch]);

  const onTogglePlayPause = () => {
    dispatch({
      type: SequencerAction.SetPlaybackState,
      payload:
        state.playState === PlaybackState.Playing ? PlaybackState.Paused : PlaybackState.Playing,
    });
  };

  const onStop = () => {
    dispatch({
      type: SequencerAction.StopPlayback,
      payload: null,
    });
  };

  const onResetPlayPositions = () => {
    dispatch({
      type: SequencerAction.ResetAudioPlayPositions,
      payload: null,
    });
  };

  return (
    <ControllerContainer>
      <ButtonContainer>
        <Button style={{ marginRight: 6 }} onClick={onResetPlayPositions} aria-label="Reset">
          <FontAwesomeIcon icon={faHistory} />
        </Button>
        <Button style={{ marginRight: 6 }} onClick={onTogglePlayPause} aria-label="Play/Pause">
          <FontAwesomeIcon icon={state.playState === PlaybackState.Playing ? faPause : faPlay} />
        </Button>
        <Button onClick={onStop} aria-label="Stop">
          <FontAwesomeIcon icon={faStop} />
        </Button>
      </ButtonContainer>
      <SequencerBPMController />
    </ControllerContainer>
  );
};
