import { sequencerConstants } from '../constants';
import { ISequencerTrack } from '../interfaces';

// Audio Tracks
const bassDrum01 = require('../assets/audio/bass-drum-01.mp3');
const snareDrum01 = require('../assets/audio/snare-01.mp3');
const hiHat01 = require('../assets/audio/hihat-closed-01.mp3');
const claps01 = require('../assets/audio/claps-01.mp3');
const snaps01 = require('../assets/audio/snaps-01.mp3');
const tomLow01 = require('../assets/audio/tom-hi-01.mp3');
const tomHi01 = require('../assets/audio/tom-low-01.mp3');
const crashCymbal01 = require('../assets/audio/crash-01.mp3');

// Tack Icons
const bassDrumIcon = require('../assets/images/bass-drum.svg');
const snareDrumIcon = require('../assets/images/snare-drum.svg');
const hiHatIcon = require('../assets/images/hi-hat.svg');
const clapIcon = require('../assets/images/clap.svg');
const snapIcon = require('../assets/images/snap.svg');
const crashIcon = require('../assets/images/crash-cymbals.svg');
const tomLowIcon = require('../assets/images/tom-low.svg');
const tomHighIcon = require('../assets/images/tom-high.svg');

let defaultPlayPositions: boolean[] = [];
for (let i = 0; i <= sequencerConstants.MAX_STEPS; ++i) {
  defaultPlayPositions[i] = false;
}

const color1 = '#ffd803';
const color2 = '#ff8906';
const color3 = '#f25f4c';
const color4 = '#e53170';
const color5 = '#aa49bb';
const color6 = '#7f5af0';
const color7 = '#35b0ff';
const color8 = '#2affa7';

export const trackData: ISequencerTrack[] = [
  {
    title: 'Bass Drum',
    audioTrack: bassDrum01,
    color: color1,
    playPositions: [...defaultPlayPositions],
    icon: bassDrumIcon,
  },
  {
    title: 'Snare Drum',
    audioTrack: snareDrum01,
    color: color2,
    playPositions: [...defaultPlayPositions],
    icon: snareDrumIcon,
  },
  {
    title: 'Hi Hat',
    audioTrack: hiHat01,
    color: color3,
    playPositions: [...defaultPlayPositions],
    icon: hiHatIcon,
  },
  {
    title: 'Claps',
    audioTrack: claps01,
    color: color4,
    playPositions: [...defaultPlayPositions],
    icon: clapIcon,
  },
  {
    title: 'Snaps',
    audioTrack: snaps01,
    color: color5,
    playPositions: [...defaultPlayPositions],
    icon: snapIcon,
  },
  {
    title: 'Tom High',
    audioTrack: tomHi01,
    color: color8,
    playPositions: [...defaultPlayPositions],
    icon: tomHighIcon,
  },
  {
    title: 'Tom Low',
    audioTrack: tomLow01,
    color: color7,
    playPositions: [...defaultPlayPositions],
    icon: tomLowIcon,
  },
  {
    title: 'Crash',
    audioTrack: crashCymbal01,
    color: color6,
    playPositions: [...defaultPlayPositions],
    icon: crashIcon,
  },
];
