import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { theme } from '../common';
import { Header } from './Header';
import { Content } from './Content';
import { Footer } from './Footer';

import { Sequencer } from '../sequencer';

const StyledLayout: React.FC = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-rows: ${({ theme }) => `${theme.headerHeight} 1fr ${theme.footerHeight}`};
`;

export const Layout: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <StyledLayout>
        <Header />
        <Content>
          <Sequencer />
        </Content>
        <Footer />
      </StyledLayout>
    </ThemeProvider>
  );
};
