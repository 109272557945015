import React, { useContext } from 'react';

import { SequencerTrack } from './SequencerTrack';
import { sequencerStore, SequencerAction } from './SequencerStore';
import { ISequencerTrack } from '../interfaces';

export const SequencerEditBay = () => {
  const sequencerContext = useContext(sequencerStore);
  const {
    dispatch,
    state: { playState, trackData = [], currentStep = 0 },
  } = sequencerContext;

  const onToggleTrackPosition = (trackIndex: number, positionIndex: number) => {
    dispatch({
      type: SequencerAction.ToggleTrackAudioPosition,
      payload: { trackIndex, positionIndex },
    });
  };

  return (
    <div>
      {trackData.map((tempTrack: ISequencerTrack, index: number) => {
        return (
          <SequencerTrack
            key={index}
            track={tempTrack}
            currentPlayState={playState}
            currentStep={currentStep}
            togglePlayPosition={(positionIndex: number) =>
              onToggleTrackPosition(index, positionIndex)
            }
          />
        );
      })}
    </div>
  );
};
